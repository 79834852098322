import React, {useEffect} from 'react';

interface MyIframeProps {
  title: string;
  url: string;
  onClose: () => void;
}

export default function MyIframe({title, url, onClose}: MyIframeProps) {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, []);
  return (
    <div className="my-iframe">
      <i className="fa fa-close" onClick={onClose} />
      <iframe src={url} title={title} />
    </div>
  );
}
